import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import img1 from 'img/help/inspect-mode/1.png';
import img2 from 'img/help/inspect-mode/2.png';
import img3 from 'img/help/inspect-mode/3.png';
import img4 from 'img/help/inspect-mode/4.png';
import img5 from 'img/help/inspect-mode/5.png';

const Content = () => {
  return (
    <div>
      <p>
        Inspect mode allows you to select a particular element during session playback, and browse
        through recordings in which users have interacted with the element you have selected.
      </p>
      <p>
        This can be helpful in case you would like to know how many users have interacted with,
        let’s say <strong>Contact us</strong> or <strong>Sign up</strong> buttons on your web page
        over time. This knowledge can be useful for further analysis in improving your site’s UX.
      </p>
      <p>To do this:</p>
      <ol>
        <li>
          <p>
            In the session replay, click on <strong>Inspect</strong>{' '}
          </p>
          <Image
            src={img1}
            lazy
            alt="Click on inspect in the upper part of screen"
            title="Click on inspect"
          />
        </li>
        <li>
          <p>Then, select the element you would like to inspect</p>
          <Image src={img2} lazy alt="Select the element of the website" title="Select element" />
        </li>
        <li>
          <p>
            You can inspect the element through the (1) <strong>CSS Selector</strong> or (2){' '}
            <strong>Text</strong> that is contained within the element
          </p>
          <Image
            src={img3}
            lazy
            alt="Inspect the chosen element through CSS Selector or Text"
            title="Inspect through CSS Selector or Text"
          />
        </li>
        <li>
          <p>
            Inspect the element by clicking <strong>Search by CSS</strong>, where the results will
            look as follows:
          </p>
          <Image src={img4} lazy alt="Results of searching through CSS Selector" title="Results" />
        </li>
        <li>
          <p>
            You can select the search results through different periods of time by clicking{' '}
            <strong>ALL TIME</strong>, as well.
          </p>
          <Image
            src={img5}
            lazy
            alt="Search inspection results through ALL TIME"
            title="Search by ALL TIME"
          />
        </li>
      </ol>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-exclude-recording/',
    title: 'How to exclude recording for a specific IP, country, URL or user agent?',
    description: 'Take a look how to exclude recording for a specific variable',
  },
  {
    link: '/help/how-to-record-keystrokes/',
    title: "How to record user's keystrokes?",
    description: 'See how to enable global keystroke tracking.',
  },
];

export const frontmatter = {
  metaTitle: 'How to use Inspect Mode?',
  metaDescription:
    'During session replay, pick a specific element and search through recordings to learn how people interact with your website.',
  canonical: '/help/how-to-use-inspect-mode/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
